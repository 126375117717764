declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef } from "react";

import NextLink from "next/link";

import { Link } from "@mui/material";

import type { LinkProps } from "@mui/material";

export type AppLinkProps<C extends React.ElementType<any> = "a"> = {
  component?: C;
} & LinkProps &
  React.ComponentPropsWithoutRef<C>;

const AppLink = <C extends React.ElementType<any> = "a">(
  props: AppLinkProps<C> & { [key: string]: any },
  ref: React.ForwardedRef<any>
) => {
  const { component: controlledComponent, ...rest } = props;

  return (
    <Link
      ref={ref}
      component={NextLink}
      underline="none"
      color="initial"
      href=""
      {...rest}
    />
  );
};

const AppLinkWithRef = forwardRef(AppLink);

export default AppLinkWithRef;
