import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "menuIconButton",
})((theme) => {
  return {
    root: {
      height: 38,
      width: 40,
      position: "relative",
      zIndex: 99999999,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      margin: theme.spacing(1.5),
      transform: "scale(0.75)",
      [theme.breakpoints.down("xl")]: {
        transform: "scale(0.7)",
      },
      [theme.breakpoints.down("md")]: {
        transform: "scale(0.6)",
      },
      [theme.breakpoints.down("sm")]: {
        transform: "scale(0.75)",
      },
    },
    centerLine: {
      position: "absolute",
      top: "50%",
      width: "100%",
      height: 2,
      transformOrigin: "50% 50%",
      transition: "transform .2s ease-in-out",
      transitionDelay: "0.2s",
    },
    topLine: {
      position: "absolute",
      height: 2,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    topLeftLine: {
      height: 2,
      //background: colors.white,
      transition: "width .2s ease-in-out",
    },
    topRightLine: {
      height: 2,
      //background: colors.white,
      transition: "width .2s ease-in-out",
    },
    bottomLine: {
      position: "absolute",
      height: 2,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      transformOrigin: "50% 50%",
    },
    bottomLeftLine: {
      height: 2,
      //background: colors.white,
      transition: "width .2s .2s ease-in-out",
    },
    bottomRightLine: {
      height: 2,
      //background: colors.white,
      transition: "width .2s .2s ease-in-out",
    },
    circle: {
      "& .MuiCircularProgress-circleDeterminate": {
        transition: "all .5s ease-in-out",
      },
      position: "absolute",
      left: -18,
      // color: "#F12711",
      color: "inherit",
    },
  };
});

export default useStyles;
