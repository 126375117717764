import { commonConfig } from "@/utils/config";
import Head from "next/head";
import React, { Suspense } from "react";

type CoreProviderProps = {
  headParams?: {
    title?: string;
    description?: string;
  };
  children: React.ReactNode;
};

const CoreProvider = (props: CoreProviderProps) => {
  const { headParams, children } = props;

  return (
    <>
      <Head>
        <title>
          {commonConfig.DOCUMENT_TITLE}{" "}
          {headParams?.title ? `| ${headParams?.title}` : ""}
        </title>
        <meta name="description" content={headParams?.description ?? ""} />
      </Head>
      {children}
    </>
  );
};

export default CoreProvider;
