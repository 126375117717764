import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "mainHeaderToolbar",
})((theme) => ({
  root: {
    minHeight: 90,
    height: 90,
    [theme.breakpoints.down("md")]: {
      minHeight: 85,
      height: 70,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 70,
      height: 70,
    },
  },
}));

export default useStyles;
