declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import { forwardRef } from "react";

import { Toolbar } from "@mui/material";

import type { ToolbarProps } from "@mui/material";

import useStyles from "./MainHeaderToolbar.styles";

type MainHeaderToolbarProps = ToolbarProps;

const MainHeaderToolbar: React.FC<MainHeaderToolbarProps> = (
  props: MainHeaderToolbarProps,
  ref: React.ForwardedRef<any>
) => {
  const { className, ...rest } = props;

  const { classes, cx } = useStyles();

  return (
    <Toolbar
      ref={ref}
      classes={{
        root: cx(classes.root, {
          [className as string]: !!className,
        }),
      }}
      disableGutters
      {...rest}
    />
  );
};

const MainHeaderToolbarWithRef = forwardRef(MainHeaderToolbar);

export default MainHeaderToolbarWithRef;
