import { useContext, useEffect, useMemo, useState } from "react"
import Box from "@mui/material/Box"

import { AppBar, Container, Hidden, SxProps, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import MainHeaderToolbar from "@/components/MainHeaderToolbar"
import AppContainer from "@/components/AppContainer"
import Logo from "@/components/Logo"

import SideOverlayNavigationMenu from "./components/SideOverlayNavigationMenu"

import useStyles from "./MainHeader.styles"
import { AppContext } from "@/contexts/app.context"
import MenuIconButton from "./components/MenuIconButton"
import AppLink from "../AppLink"

const MainHeader = () => {
  const { app, setFieldApp } = useContext(AppContext)!

  const appHeaderTheme = app.theme.appHeader

  const theme = useTheme()

  const textColor = app.openMenu
    ? theme.palette.common.white
    : (appHeaderTheme.textColor === "default"
        ? theme.palette.common.black
        : appHeaderTheme.textColor) || theme.palette.common.black

  const bgColor = app.openMenu
    ? "transparent"
    : appHeaderTheme.backgroundColor === "default"
    ? "rgba(255, 255, 255, 0.3)"
    : appHeaderTheme.backgroundColor

  const rootSx = useMemo<SxProps>(() => {
    return {
      backgroundColor: bgColor,
      color: textColor,
    }
  }, [textColor, bgColor])

  const { classes } = useStyles()

  const handleToggleMenu = () => {
    setFieldApp("openMenu", !app.openMenu)
  }

  return (
    <>
      <AppBar color="default" elevation={0} sx={rootSx} position="fixed">
        <AppContainer>
          <MainHeaderToolbar>
            <AppLink
              href="/"
              display={"flex"}
              onClick={() => setFieldApp("openMenu", false)}
              alignItems="center">
              <Logo
                variant={
                  [
                    theme.palette.common.white,
                    "#000",
                    "#fff",
                    "white",
                  ].includes(textColor)
                    ? "default"
                    : "reverseLogo"
                }
                className={classes.logo}
              />
              <Typography
                className={classes.logoText}
                variant="logo"
                color={
                  [
                    theme.palette.common.white,
                    "#000",
                    "#fff",
                    "white",
                  ].includes(textColor)
                    ? textColor
                    : "rgba(193, 151, 24, 1)"
                }>
                Vitalink Technology (APAC)
              </Typography>
            </AppLink>
            <Box flexGrow={1} />
            <MenuIconButton
              open={app.openMenu}
              color={textColor}
              onClick={handleToggleMenu}
            />
          </MainHeaderToolbar>
        </AppContainer>
      </AppBar>
      <SideOverlayNavigationMenu
        open={app.openMenu}
        onMenuClick={handleToggleMenu}
      />
    </>
  )
}

export default MainHeader
