import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "logo",
})((theme) => {
  return {
    root: {
      display: "flex",
    },
    logoImg: {
      height: 72,
    },
  };
});

export default useStyles;
