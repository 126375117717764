import MainHeader from "@/components/MainHeader";
import { useEffect } from "react";

import useStyles from "./Main.styles";

type MainProps = {
  children: React.ReactNode;
};

const Main = (props: MainProps) => {
  const { children } = props;

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <MainHeader />
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export default Main;
