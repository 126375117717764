import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "main">({
  name: "main",
  uniqId: "VY4iMp",
})((theme, _, classes) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
    },
    main: {
      flexGrow: 1,
      width: "100%",
    },
  };
});

export default useStyles;
