import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(theme => {
  return {
    logo: {
      marginRight: theme.spacing(2.5),
      [theme.breakpoints.down("md")]: {
        height: 70 - 16,
      },
    },
    logoText: {
      marginTop: 44,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
        marginTop: 34,
        marginLeft: -12,
        whiteSpace: "nowrap",
      },
    },
  }
})

export default useStyles
