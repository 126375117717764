import AppContainer from "@/components/AppContainer"
import AppLink from "@/components/AppLink"
import MainHeaderToolbar from "@/components/MainHeaderToolbar"
import { Drawer, Hidden, Typography } from "@mui/material"
import React, { MouseEventHandler } from "react"

import { ReactComponent as MainHeaderBigLogoSvg } from "@/assets/images/main-header-big-logo.svg"

import useStyles from "./SideOverlayNavigationMenu.styles"

type SideOverlayNavigationMenuProps = {
  open?: boolean
  onMenuClick?: MouseEventHandler
}

const menus = [
  {
    title: "Our Solutions",
    path: "/our-solutions",
  },
  {
    title: "About Us",
    path: "/about-us",
  },
  {
    title: "Contact Us",
    path: "/contact-us",
  },
]

const SideOverlayNavigaionMenu = (props: SideOverlayNavigationMenuProps) => {
  const { open, onMenuClick } = props
  const { classes } = useStyles()

  return (
    <Drawer
      classes={{
        root: classes.drawer,
        paperAnchorTop: classes.drawerPaperAnchorTop,
      }}
      transitionDuration={500}
      anchor="top"
      open={open}
      // open
    >
      <MainHeaderToolbar />
      <Hidden lgDown>
        <AppContainer className={classes.container}>
          <MainHeaderBigLogoSvg className={classes.mainHeaderBigLogoSvg} />
          <div className={classes.main}>
            <div className={classes.menuList}>
              {menus.map((menu, menuIndex) => (
                <AppLink
                  key={menuIndex}
                  className={classes.menuItem}
                  href={menu.path}
                  data-aos="fade-up"
                  data-aos-duration={1000}
                  data-aos-delay={menuIndex * 200}
                  onClick={onMenuClick}>
                  {/* <span className={classes.menuItemNumber}>{`0${
                    menuIndex + 1
                  }`}</span> */}
                  <span className={classes.menuItemTitle}>{menu.title}</span>
                </AppLink>
              ))}
            </div>

            <div
              data-aos="fade-up"
              data-aos-duration={1000}
              className={classes.office}>
              <p className={classes.officeName}>SMART Crafts Future</p>
              <p className={classes.officeAddress}>
                Turn your ambitions into reality
              </p>
            </div>
          </div>
        </AppContainer>
      </Hidden>
      <Hidden lgUp>
        <AppContainer className={classes.mobileContainer}>
          <main className={classes.mobileMain}>
            <div className={classes.mobileMenuList}>
              {menus.map((menu, menuIndex) => (
                <Typography
                  key={menuIndex}
                  className={classes.mobileMenuItem}
                  variant="h5"
                  component={AppLink}
                  href={menu.path}
                  data-aos="fade-up"
                  data-aos-duration={1000}
                  data-aos-delay={menuIndex * 200}
                  onClick={onMenuClick}>
                  {menu.title}
                </Typography>
              ))}
            </div>
          </main>
        </AppContainer>
      </Hidden>
    </Drawer>
  )
}

export default SideOverlayNavigaionMenu
