import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles<void, "menuItemNumber" | "menuItemTitle">({
  name: "sideOverlayNavigationMenu",
})((theme, _, classes) => {
  return {
    drawer: {
      zIndex: theme.zIndex.appBar - 1,
    },
    drawerPaperAnchorTop: {
      height: "100%",
      backgroundColor: "#C19718",
      color: theme.palette.common.white,
      overflow: "hidden",
    },
    container: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      fontFamily: "Inria Serif",
    },
    mainHeaderBigLogoSvg: {
      position: "absolute",
      height: 1248,
      top: "50%",
      transform: "translateY(calc(-50% - 98px))",
      right: -676,
    },
    main: {
      position: "relative",
      display: "flex",
    },
    menuList: {
      gap: 74,
      marginLeft: "calc(100%*224/1728)",
      display: "flex",
      flexDirection: "column",
    },
    menuItem: {
      display: "flex",
      alignItems: "flex-start",
      position: "relative",
      color: "inherit",
      "&:hover": {
        [`& .${classes.menuItemTitle}:before`]: {
          width: "100%",
        },
      },
    },
    menuItemNumber: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: "-0.06em",
      position: "absolute",
      top: 0,
      left: 0,
    },
    menuItemTitle: {
      fontSize: theme.typography.pxToRem(96),
      fontWeight: 700,
      letterSpacing: "-0.02em",
      lineHeight: "76px",
      marginLeft: theme.spacing(10),
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "0%",
        height: 6,
        backgroundColor: theme.palette.common.white,
        transform: "translateY(-50%)",
        transition: theme.transitions.create(["width"]),
      },
      [theme.breakpoints.up("md")]: {
        fontSize: `${theme.typography.pxToRem(75)}`,
        lineHeight: "125px",
      },
    },

    office: {
      position: "absolute",
      right: theme.spacing(2.5),
      bottom: 0,
      width: 394,
    },
    officeName: {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 700,
      lineHeight: "140%",
      letterSpacing: "-0.02em",
      marginBottom: theme.spacing(1.5),
    },
    officeAddress: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: 400,
      lineHeight: "140%",
      letterSpacing: "0.02em",
    },

    mobileContainer: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
    },
    mobileMain: {
      flexGrow: 1,
    },
    mobileMenuList: {
      marginTop: "calc(100vh*116/812)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(4.25),
    },
    mobileMenuItem: {
      color: "inherit",
      textAlign: "center",
    },
  }
})

export default useStyles
