import { useState } from "react";

import { Box, CircularProgress } from "@mui/material";

import useStyles from "./MenuIconButton.styles";

type MenuIconButtonProps = {
  open?: boolean;
  color?: string;
} & React.ComponentProps<"div">;

const MenuIconButton = (props: MenuIconButtonProps) => {
  const { open, color, ...rest } = props;
  const [hover, setHover] = useState(false);

  const { classes } = useStyles();

  const handleHover = () => {
    setHover(true);
  };

  const handleLeave = () => {
    setHover(false);
  };

  return (
    <div
      className={classes.root}
      {...rest}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <CircularProgress
        variant="determinate"
        value={hover || !!open ? 100 : 0}
        sx={(theme) => ({
          svg: {
            color,
            transform: hover ? "rotate(180deg)" : "rotate(90deg)",
            transition: theme.transitions.create(["transform"], {
              duration: theme.transitions.duration.standard,
            }),
          },
          //  [`& .${circularProgressClasses.circle}`]: {
          //    strokeLinecap: "round",
          //  },
        })}
        size={"180%"}
        thickness={2}
        className={classes.circle}
      />
      <Box
        className={classes.topLine}
        sx={{
          top: open ? "50%" : "25%",
          transform: open ? "rotate(-45deg)" : "rotate(0deg)",
          transition: open
            ? "top .2s ease-in-out, transform .2s .2s ease-in-out"
            : "top .2s ease-in-out .2s, transform .2s ease-in-out",
        }}
      >
        <Box
          className={classes.topLeftLine}
          sx={{ width: open ? "40%" : "60%", background: color }}
        />
        <Box
          className={classes.topRightLine}
          sx={{ width: open ? "40%" : "30%", background: color }}
        />
      </Box>
      <Box
        className={classes.centerLine}
        sx={{
          background: color,
          transform: open ? "rotateZ(45deg)" : "rotateZ(0deg)",
        }}
      />
      <Box
        className={classes.bottomLine}
        sx={{
          top: open ? "50%" : "75%",
          transform: open ? "rotate(-45deg)" : "rotate(0deg)",
          transition: open
            ? "top .2s ease-in-out, transform .2s 0.2s ease-in-out"
            : "top .2s ease-in-out .2s, transform .2s ease-in-out",
        }}
      >
        <Box
          className={classes.bottomLeftLine}
          sx={{ width: open ? "40%" : "30%", background: color }}
        />
        <Box
          className={classes.bottomRightLine}
          sx={{ width: open ? "40%" : "60%", background: color }}
        />
      </Box>
    </div>
  );
};

export default MenuIconButton;
