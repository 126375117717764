import { ComponentProps, forwardRef, Fragment } from "react";

import AppLink from "@/components/AppLink";

import { Box, SxProps } from "@mui/material";

import useStyles from "./Logo.styles";

type LogoProps = {
  href?: string;
  sx?: SxProps;
  variant?: "default" | "reverseLogo";
} & ComponentProps<"img">;

const logoVariantPathMap = {
  default: "/images/logo.svg",
  reverseLogo: "/images/reverse-logo.svg",
};

const Logo = (
  props: LogoProps & { [key: string]: any },
  ref: React.ForwardedRef<any>
) => {
  const { href, variant = "default", ...rest } = props;

  const { classes } = useStyles();

  const WrapperComponent = href ? AppLink : "div";

  return (
    <WrapperComponent className={classes.root} href={href}>
      <Box
        ref={ref as any}
        component="img"
        className={classes.logoImg}
        src={logoVariantPathMap[variant]}
        alt="Vitalink"
        {...rest}
      />
    </WrapperComponent>
  );
};

const LogoWithRef = forwardRef(Logo);

export default LogoWithRef;
